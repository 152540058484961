html, body {
    height: 100%; width: 100%;
    margin: 0px 0px;
    padding: 0px 0px;
    overflow-x: hidden;
}

.wrapperBtnHome{
    font-family: 'Arvo', serif;
    position: absolute;
    bottom: 0px;
    width:100%;
    height:300px;
    overflow:hidden;
}
.wrapperBtnHome div {
    opacity: 0.7;
}
.btnSummer{
    float: left;
    width:35%;
    min-width: 350px;
    height:470px;
    margin-bottom:0px;
    background-color:white;
    text-align:center;
    transform:skewY(-64deg);
}

.btnSummer p{
    padding-left: 120px;
    text-align: center;
    transform:skewY(64deg);
    line-height:220px;
    font-size:40px;
    color:black;
}

.btnWinter{
    float: right;
    min-width: 350px;
    width:48%;
    height:470px;
    margin-bottom:0px;
    background-color: #222222;
    text-align:center;
    transform:skewY(-64deg);
}

.btnWinter p{
    padding-left: 110px;
    text-align: center;
    transform:skewY(64deg);
    line-height:220px;
    font-size:40px;
    color:white;
}

#homebg  {
    height: 100%; width: 100%;
    background: url("../img/home.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

#homelogo {
     margin-top: 50px;

}


#topmenu {
    top: 0px;
    position: fixed;
    width: 100%; height: 64px;
    background-color: rgb(45, 45, 45);
}


#logo {
    background-repeat: no-repeat;
    background-image: url("../img/logo.jpg");
    height: 64px;
    background-position: 50px 0px;
}

#logo_winter {
    background-repeat: no-repeat;
    background-image: url("../img/logo_white.jpg");
    height: 64px;
    background-position: 50px 0px;
}


#headTitle {
    font-family: 'Titillium Web', sans-serif;
    font-weight:  normal;
    text-transform: none;
    font-size: 100px;

}
#topmenu {
    z-index: 50;
}
#topmenu ul {
    float: right;
    padding-top: 5px;
}
#topmenu ul li {
    font-family: 'Cuprum', sans-serif;
    text-transform: uppercase;
    list-style: none;
    color: white;
    font-size: 1.2em;
    line-height: 60px;
    margin-left: 40px;
    text-align: center;
    -webkit-transition: transform 0.5s, ; /* Safari */
    transition: transform 0.5s, padding 0.5s;
}
#topmenu ul li.winter a {
    color: black;
}

#topmenu ul li:hover {
    -ms-transform: rotate(-3deg); /* IE 9 */
    -webkit-transform: rotate(-3deg); /* Chrome, Safari, Opera */
    transform: rotate(-3deg);
    padding-top: 5px;
}
#topmenu ul li a {
    text-decoration: none;
    color: white;
}
div.module.parallax {
    height: 100%;
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}




/* pagina prodotti */
.colscroll {
    width: 100%;
}

.colscroll .row {
    margin: -20px 0px 0px 0px;
}

.wrapper {
    margin: 0px 0px 0px 0px;
    z-index: -10;
    top: 64px;
    position: relative;
    width: 100%;
}
.wrapper div {
    padding: 0px 0px;
}

.wrapper .box {
    padding: 30px 20px 0px 20px;

}

.colscroll div {
    line-height: 100px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
}


#map {
    width: 100%;
    height: 100%;
}

    .prenotaBgsummer {

        background-image: url('../img/bg_prenota_summer.png') !important;
        background-size: 50% 100% !important;
        background-position: left center !important;
    }


.prenotaBgwinter {

    background-image: url('../img/bg_prenota_winter.png') !important;
    background-size: 50% 100% !important;
    background-position: left center !important;
}

#cookie-bar {background:#111111; height:auto; line-height:24px; color:#eeeeee; text-align:center; padding:3px 0;}
#cookie-bar.fixed {position:fixed; bottom:0; left:0; width:100%;}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {margin:0; padding:0;}
#cookie-bar a {color:#ffffff; display:inline-block; border-radius:3px; text-decoration:none; padding:0 6px; margin-left:8px;}
#cookie-bar .cb-enable {background:#007700;}
#cookie-bar .cb-enable:hover {background:#009900;}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#0033bb;}
#cookie-bar .cb-policy:hover {background:#0055dd;}